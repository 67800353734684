import(/* webpackMode: "eager" */ "/home/runner/_work/prescriber-point/prescriber-point/node_modules/@prescriberpoint/ui/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/_work/prescriber-point/prescriber-point/providers/Provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/prescriber-point/prescriber-point/styles/fonts.css");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/prescriber-point/prescriber-point/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/prescriber-point/prescriber-point/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/prescriber-point/prescriber-point/node_modules/react-toastify/dist/ReactToastify.min.css");
