'use client';
import {QueryClientProvider, QueryClient} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {FC, ReactNode, useEffect, useState} from 'react';
import {Cookies, CookiesProvider} from 'react-cookie';
import {FlagsProvider, FlagsType} from '@/context/FlagsContext';
import {UserAgentProvider} from '@/context/UserAgentContext';
import {parse, UserAgent} from '@/utils/userAgent';
import ToastContainerHolder from '@/components/ToastContainer';

interface IProviderProps {
  children: ReactNode;
  userAgent?: UserAgent;
  cookies: any;
  flags: FlagsType;
}

const Provider: FC<IProviderProps> = ({
  children,
  userAgent,
  cookies: universalCookies,
  flags,
}) => {
  const [ua, setUa] = useState<UserAgent | undefined>(userAgent);

  useEffect(() => {
    setUa(parse(window.navigator.userAgent));
  }, []);

  return (
    <QueryClientProvider client={new QueryClient()}>
      <CookiesProvider cookies={new Cookies(universalCookies)}>
        <UserAgentProvider userAgent={ua}>
          <FlagsProvider flags={flags}>
            <ToastContainerHolder />
            {children}
          </FlagsProvider>
        </UserAgentProvider>
      </CookiesProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
export default Provider;
